@import "../../styles/variables";

.buttonContainer {
  border-radius: 6px;
  border: none;
  background: var(--primary-themeColor);
  height: 45px;
  cursor: pointer;
  @include font(16px, 600, white);
  padding: 10px 20px;
}
