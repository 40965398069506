@mixin flexCenter($gap: initial) {
  display: flex;
  align-items: center;
  gap: $gap;
}

@mixin font($fontSize: 1rem, $fontWeight: 300, $color: black) {
  font-size: $fontSize;
  font-weight: $fontWeight;
  color: $color;
}

@mixin lightButton() {
  background: transparent !important;
  color: #666 !important;
  border: 1px solid var(--primary-themeColor) !important;
}

@mixin gridColumn($columns: 2) {
  display: grid;
  grid-template-columns: repeat($columns, minmax(50px, 1fr));
  grid-gap: 1rem;

  @media (max-width: 880px) {
    grid-template-columns: repeat(2, minmax(50px, 1fr));
  }

  @media (max-width: 500px) {
    grid-template-columns: repeat(1, minmax(50px, 1fr));
  }
}

@mixin skeleton($width) {
  animation: skeleton-loading 1.2s linear infinite alternate;
  width: $width;
  border-radius: 10px;
  @keyframes skeleton-loading {
    0% {
      background-color: hsl(200, 20%, 80%);
    }

    100% {
      background-color: hsl(200, 20%, 95%);
    }
  }
}
