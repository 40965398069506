.light-theme {
  --primary-bgColor: #fefefe;
  --secondary-bgColor: #f4f7fe;
  --primary-themeColor: #8d773b;
  --font-color: #666;
  --secondary-fontColor: #84838f;
  --black-color: #000000;
  --dark-grayish-blue: #586172;
  --oasis: #FFEBBE;
  --golden-yellow: #E49E00;
  --water-leaf: #BCFAEA;
  --caribbean-green: #02BF90;
  --blue-magenta: #C3B8F6;
  --neon-blue: #5937F1;
  --whisper-rose: #F6D9D9;
  --carnelian: #B11313;
}
