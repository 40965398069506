body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  box-sizing: border-box;
  position: relative;
  /* max-width: 1400px;
  margin: auto; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

figure {
  margin: 0;
}

img {
  width: 100%;
  height: 100%;
}

p,
h1 {
  margin-block: 5px;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
  padding-left: 0;
}

.errorMessage {
  color: #ff2e2e;
  font-size: 12px;
  font-weight: 400;
  margin: 5px 0 0 5px;
}

svg {
  cursor: pointer;
}

.container {
  background: white;
  padding: 20px;
  border-radius: 6px;
}

input[type="date"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  &::-webkit-calendar-picker-indicator {
    cursor: pointer;
  }
  &::-moz-appearance-calendar-picker-indicator {
    cursor: pointer;
  }
}

.hyperLink {
  color: #1d53b5;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.inputIconContainer {
  position: absolute;
  top: 50%;
  right: 3%;
  transform: translate(-50%, -50%);
  background: white;
}

.tableContainer {
  overflow: auto;
  border-radius: 6px;
}

.tableContainer::-webkit-scrollbar {
  height: 0.375rem;
  width: 0.375rem;
  border-radius: 3.125rem;
}

.tableContainer::-webkit-scrollbar-thumb {
  border-radius: 3.125rem;
  -webkit-box-shadow: inset 0 0 100px var(--primary-themeColor);
}

.link {
  color: #181c5c;
  cursor: pointer;
  font-weight: 500;
}

/* .rbc-event {
  left: 0% !important;
  width: 100% !important;
} */

/*
 This is causing inconsitency of data in month view calendar
.rbc-event-content {
  font-size: 12px !important;
} */

/* Class to remove spacing from slots in day and week view */
.rbc-day-slot .rbc-events-container {
  margin-right: 0 !important;
}

/* Hide the current time indicator green line */
/* .rbc-current-time-indicator {
  display: none;
} */

/*
 To add the scrolling in month view if slots are multiple
.rbc-row-content-scroll-container {
  height: 100%;
  overflow-y: auto !important;

  &::-webkit-scrollbar {
    width: 10px !important;
  }
  scrollbar-width: thin !important;
  -ms-overflow-style: scrollbar !important;
} */

.rbc-overlay {
  max-height: 150px !important;
  overflow: auto;
  max-width: 400px !important;
}

.maxwidth {
  width: max-content;
}
