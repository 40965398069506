.errorMessage {
  font-weight: 600;
  font-size: 22px;
}

.errorContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  //   min-height: 500px;
}
